.contact-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -moz-column-gap: 5%;
  column-gap: 5%;
  line-height: 110%;
  margin-bottom: 50px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chelsea-contact-details {
  display: flex;
  align-items: center;
}

.chelsea-contact-details a { font-size: 80%; }

.chelsea-contact-details > div {
  display: flex;
  flex-direction: column;
}

.contact-icons { align-self: flex-start; }

.location-details { margin-left: 60px; }

.location-details div { 
  font-family: var(--opensans);
  font-size: 95%;
}

.map-container { 
  display: flex;
}

.map { width: 100%; }

@media only screen and (max-width: 1023px) {
  .chelsea-contact-details > div { font-size: 90%; }
  .location-details{ margin-top: 20px; }
}

@media only screen and (max-width: 896px) {
  .chelsea-contact-details a { font-size: 90%; }
}

@media only screen and (max-width: 767px) {
  .contact-details-grid { grid-template-columns: 1fr; }
  .location-details { 
    margin-bottom: 30px;
    margin-left: 30px;
  }
  .map { 
    width: 80%;
    margin: auto; 
  }
}