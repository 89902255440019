.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -moz-column-gap: 15px;
  column-gap: 15px;
  row-gap: 30px;
}

.cover-photo { 
  width: 100%;
  max-height: 263px;
}

.cover-photo-title {
  text-align: center;
  margin-top: 15px;
}

@media only screen and (max-width: 1200px) {
  .cover-photo { 
    max-height: 216px;
  }
}

@media only screen and (max-width: 1023px) {
  .cover-photo { 
    max-height: 183px;
  }
}

@media only screen and (max-width: 896px) {
  .cover-photo { 
    max-height: 178px;
  }
}

@media only screen and (max-width: 767px) {
  .gallery-grid { grid-template-columns: repeat(2, 1fr); }
  .cover-photo { 
    max-height: 183px;
  }
}

@media only screen and (max-width: 480px) {
  .cover-photo { 
    max-height: 139px;
  }
}