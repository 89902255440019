.footer {
  font-size: 60%;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 10px auto;
  white-space: nowrap;
}

.copyright {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}


.footer span { color: #acacac; }