/* Just Lovely Font */
@import url("//hello.myfonts.net/count/3ab5f0");
  
@font-face {
  font-family: 'JustLovely';
  src: url('./fonts/JustLovely/3AB5F0_3_0.eot');
  src: url('./fonts/JustLovely/3AB5F0_3_0.eot?#iefix') format('embedded-opentype'),
  url('./fonts/JustLovely/3AB5F0_3_0.woff2') format('woff2'),
  url('./fonts/JustLovely/3AB5F0_3_0.woff') format('woff'),
  url('./fonts/JustLovely/3AB5F0_3_0.ttf') format('truetype');
}

/* Lemon Milk Font */
@font-face {
  font-family: 'lemonmilk_lightregular';
  src: url('./fonts/LemonMilk/lemonmilklight-webfont.woff2') format('woff2'),
       url('./fonts/LemonMilk/lemonmilklight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --font-color: #444;
  --red-color: #fc0013;
  --dk-red-color: #a7000d;
  --lemonmilk: 'lemonmilk_lightregular', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  --justlovely: 'JustLovely';
  --opensans: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--lemonmilk);
  color: var(--font-color);
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media only screen and (max-width: 1200px) {
  body { font-size: 18px; } 
}

@media only screen and (max-width: 896px) {
  body { font-size: 16px; }
}