.testimonial-grid {
  width: 90%;
  line-height: 145%;
  display: grid;
  grid-template-columns: 30% 1fr;
  -webkit-column-gap: 10%;
  -moz-column-gap: 10%;
  column-gap: 10%;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 50px;
}

.divider { margin-top: 30px; }

.divider hr {
  width: 80%;
  margin: 0 auto;
  height: 1px;
  border: none;
  background-color: #ddd;
}

.stars-and-name {
  height: 250px;
  width: 250px;
  position: relative;
  justify-self: end;
}

.stars-and-name img:first-of-type { 
  height: 100%;
  width: 100%;
}

.stars-and-name > div {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
}

.stars {
  font-size: 80%;
  margin-bottom: 8px;
}

.review { 
  font-style: italic;
  display: -webkit-box;
  display: flex;
  font-family: var(--opensans);
}

.quotation-mark {
  font-size: 400%;
  font-family: monospace;
  line-height: 100%;
  margin-right: 30px;
}

.review-text { margin-top: 10px; }

@media only screen and (max-width: 1023px) {
  .stars-and-name { 
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 896px) {
  .testimonial-grid { 
    -webkit-column-gap: 5%; 
    -moz-column-gap: 5%; 
    column-gap: 5%; 
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-grid { 
    width:100%;
    grid-template-columns: 1fr; 
  }

  .stars-and-name { 
    width: 180px;
    height: 180px;
    justify-self: center;
  }
}