.nav-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding: 10px 40px;
  font-size: 75%;
  letter-spacing: 1px;
  z-index: 9999;
  box-shadow: 0 2px 2px #5a5a5a;
}

.cc-symbol-wrapper {
  height: 55px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  cursor: pointer;
}

.cc-symbol-wrapper > img {
  height: 100%
}

.nav-links-wrapper {
  flex-basis: 70%;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}

.nav-link {
  text-decoration: none;
  color: var(--font-color);
  padding: 8px 0;
  border-bottom: solid 1px transparent;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.active, .nav-link:hover{
  color: var(--red-color);
  border-bottom: solid 1px var(--red-color);
}

.mobile-nav-icon { 
  display: none;
  font-size: 150%;
  cursor: pointer;
}

.mobile-nav-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 10px 20px;
}

.mobile-nav-bar a { padding: 10px; }
.mobile-nav-bar .active, .mobile-nav-bar .nav-link:hover { border-bottom: transparent; }

@media only screen and (max-width: 1023px) {
  .nav-link { 
    padding: 8px;
    margin: 0 1px;
  }
}

@media only screen and (max-width: 896px) {
  .cc-symbol-wrapper { height: 40px; }
}

@media only screen and (max-width: 767px) {
  .nav-links-wrapper { display: none; }
  .mobile-nav-icon { 
    display: -webkit-inline-box; 
    display: inline-flex; 
  }
}