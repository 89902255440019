.social-icons-wrapper{
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  font-size: 200%;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.two-icons { grid-template-columns: repeat(2, 1fr); }
.three-icons { grid-template-columns: repeat(3, 1fr); }

.social-icons-wrapper a { 
  align-self: flex-start;
  margin: 0 auto;
}

.social-icons-wrapper i,
.social-icons-wrapper i:link,
.social-icons-wrapper i:visited { color: var(--font-color); }

.social-icons-wrapper i:hover,
.social-icons-wrapper i:active { color: var(--red-color); }

.icon-and-text {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.icon-text {
  font-size: 30%;
  text-align: center;
}

@media only screen and (max-width: 896px) {
  .icon-text { font-size: 40%; }
}