.timetable {
  width: 100%;
  text-align: center;
  font-size: 80%;
}

.timetable tbody:nth-child(even) { background-color: #f6f6f6;}

.timetable-extra-info { font-size: 70%; }

.sub-timetable-info-wrapper {
  font-size: 80%;
  margin: 40px 0;
}

.sub-timetable-info-wrapper { white-space: nowrap; }

.sub-timetable-info {
  margin-top: 10px;
}

.download-timetable {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.download-timetable > div { margin-right: 5px; }

@media only screen and (max-width: 1023px) {
  .timetable {
    width: 110%;
    margin: 0 -5%;
  }

  .sub-timetable-info-wrapper {
    width: 110%;
    margin: 40px -5%;
    font-size: 75%;
  }
}

@media only screen and (max-width: 896px) {
  .timetable { 
    width: 100%;
    margin: 0;
    font-size: 75%;
  } 

  .timetable-extra-info { font-size: 95%; }

  .sub-timetable-info-wrapper {
    width: 100%;
    margin: 35px 0;
  }
}

@media only screen and (max-width: 767px) {
  .sub-timetable-info-wrapper { 
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal; 
    flex-direction: column;
    font-size: 90%;
  }

  .sub-timetable-info {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4px;
    margin-left: 0;
  }

  .timetable { 
    width: 180%; 
    font-size: 70%;
  }
}