.home-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 40px;
}

.home-page hr {
  margin: 0 auto 50px auto;
}

.cc-choreography {
  height: 150px;
  display: flex;
  align-items: center;
  margin: 30px auto 10px auto;
  z-index: 900;
  font-weight: 100;
  letter-spacing: 5px;
}

.tag-line { 
  font-family: var(--justlovely);
  font-weight: 200;
  line-height: 110%;
  letter-spacing: 2px;
  text-align: center;
  margin: 0 auto 15px auto;
  z-index: 900;
}

.cc-choreography, .tag-line { font-size: 400%; }

.img-wrapper-left, .img-wrapper-right {
  position: absolute;
  height: 300px;
  top: 100px;
}

.img-wrapper-left { left: 10px; }
.img-wrapper-right { right: 10px; }

.img-wrapper-left > img, .img-wrapper-right > img { height: 100%; }

.divider-small-screen { display: none; }

.home-page-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto 20px auto;
}

.home-page-button {
  text-decoration: none;
  border: solid 1px var(--red-color);
  background-color: var(--red-color);
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  margin: 10px;
  text-align: center;
}

.home-page-button:hover {
  background-color: var(--dk-red-color);
  border-color: var(--dk-red-color);
  color: #fff;
}

.key-statements {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 90%;
  margin: 40px auto;
  justify-content: space-between;
  z-index: 900;
}

.home-page-gallery {
  display: none;
  margin: 0 auto;
  height: 45vh;
}

.home-page-gallery div, .home-page-gallery img { height: 100%; }

@media only screen and (max-width: 1200px) {
  .cc-choreography, .tag-line { font-size: 350%; } 
  .key-statements { font-size: 85%; }
}

@media only screen and (max-width: 1023px) {
  .cc-choreography, .tag-line { font-size: 300%; }
  .img-wrapper-left, .img-wrapper-right { 
    height: 200px;
    top: 150px;
  }
  .home-page-buttons { margin-bottom: 0; }
  .home-page-button { font-size: 90%; }
  .key-statements { justify-content: space-evenly; }
  .key-statements > div { padding: 15px 35px; }
}

@media only screen and (max-width: 896px) {
  .cc-choreography {
    height: 100px;
    margin-top: 80px;
    letter-spacing: 4px;
  }
  .cc-choreography, .tag-line { font-size: 300%; }
  .img-wrapper-left, .img-wrapper-right { 
    height: 200px;
    top: 150px;
  }
  .key-statements > div { padding: 15px 25px; }
}

@media only screen and (max-width: 767px){
  .cc-choreography { 
    font-size: 210%; 
    height: 80px;
    margin-top: 20px;
    letter-spacing: 3px;
  }
  .tag-line { font-size: 230%; }
  .home-page-buttons { 
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 15px;
  }
  .home-page-button { font-size: 80%; }
  .key-statements { display: none; }
  .home-page-gallery { display: block; }
  .img-wrapper-left, .img-wrapper-right { display: none; }
  .home-page hr { margin-bottom: 30px; }
  .divider-small-screen { display: block; }
  .divider-large-screen { display: none; }
}

@media only screen and (max-width: 480px) {
  .cc-choreography { 
    font-size: 175%; 
    letter-spacing: 2px;
    height: 60px;
    margin-top: 15px;
  }
  .tag-line { 
    font-size: 230%;
    margin-top: 0;
  }
  .home-page-gallery { height: 35vh; }
}