.about-grid, .about-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 5%;
  -moz-column-gap: 5%;
  column-gap: 5%;
  -webkit-box-align: center;
  align-items: center;
}

.about-grid-small img { width: 75%; }

.about-grid-small div:first-of-type { text-align: right; }

.about-text {
  font-size: 90%;
  font-family: var(--opensans);
  line-height: 145%;
}

.about-icons {
  width: 200px;
  margin: 10px auto;
}

.download-info-pack {
  display: -webkit-box;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-family: var(--lemonmilk);
  margin: 30px 0;
}

.download-info-pack div { margin-right: 5px; }

.about-sub-heading {
  text-align: center;
  font-weight: 200;
  letter-spacing: 2px;
  font-family: var(--justlovely);
  font-size: 250%;
  margin: 60px 0 20px 0;
}

.we-offer {
  line-height: 1.5;
}

.social-and-download-large-screen { text-align: center; }

.social-and-download-small-screen {
  text-align: center;
  display: none;
  font-family: var(--opensans);
  margin-top: 20px;
}

@media only screen and (max-width: 1200px) {  
  .about-text { font-size: 82%; }
}

@media only screen and (max-width: 1023px) {
  .about-grid {
    width: 100%;
    margin: 0;
  }
  .about-grid .logo-wrapping-image {
    height: 400px;
    width: 400px;
  }
  .about-grid-2 img {
    height: 250px;
  }
  .tiny-tots-image { margin: 15px auto auto auto !important; }
  .about-text { font-size: 90%; }
  .dance-styles-grid { grid-template-columns: repeat(3, 1fr); }
  .download-info-pack { margin: 10px 0; }
}

@media only screen and (max-width: 896px) {
  .about-text { font-size: 100%; }
  .social-and-download-small-screen { display: block; }
  .social-and-download-large-screen { display: none; }
  .about-grid .logo-wrapping-image {
    height: 300px;
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .about-grid { 
    display: flex;
    flex-direction: column-reverse;
    /* grid-template-columns: repeat(1, 1fr);  */
  }
  .about-grid-small div:first-of-type, .about-grid-small div { text-align: center; }
  .dance-styles-grid { grid-template-columns: repeat(2, 1fr); }
}

.about-image {
  height: 300px;
  margin: auto;
}