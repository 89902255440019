.fees-grid-large-screen {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -webkit-column-gap: 50px;
  -moz-column-gap: 50px;
  column-gap: 50px;
}

.fees-grid-small-screen { display: none; }

.fees-table { 
  font-size: 85%; 
  margin: 0 auto;
  width: stretch;
}

.fees-table th { text-align: left; }

.fees-table td, .fees-table th { 
  width: 50%; 
  vertical-align: baseline;
}

.fees-discount { margin-top: 20px; }

.fees-text {
  width: 80%;
  margin: 50px auto;
  line-height: 145%;
}

.fees-small-text { font-size: 80%; }

.fees-subheading { 
  margin: 20px 0; 
  color: var(--red-color);
  font-size: 110%;
}

.fees-info { 
  font-family: var(--opensans);
  font-size: 90%;
}

@media only screen and (max-width: 1023px) {
  .fees-grid-large-screen {
    -webkit-column-gap: 5px;
    -moz-column-gap: 5px;
    column-gap: 5px;
    font-size: 95%;
  }
}

@media only screen and (max-width: 896px) {
  .fees-text { width: 100%; }
}

@media only screen and (max-width: 896px) {
  .fees-grid-large-screen { display: none; }
  .fees-grid-small-screen { display: block; }
}
