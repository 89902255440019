.logo-wrapping-image {
  height: 230px;
  width: 230px;
  position: relative;
}

.logo-wrapping-image.auto-height {
  height: auto;
  width: auto;
}

.logo-wrapping-image img:first-of-type { 
  height: 100%;
  width: 100%;
}

.logo-wrapping-image img:nth-child(2) {
  height: 65%;
  width: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
}

@media only screen and (max-width: 1023px) {
  .logo-wrapping-image { 
    width: 165px;
    height: 165px;
  }
}

@media only screen and (max-width: 480px) {
  .logo-wrapping-image {
    height: 140px;
    width: 140px;
  }
}