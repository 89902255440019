.page-wrapper {
  width: 80%;
  padding: 40px 0;
  margin: 0 auto;
}

.page-title{
  text-align: center;
  font-size: 325%;
  font-weight: 100;
  letter-spacing: 5px;
  margin: 20px 0;
}

.small-page-title.page-title { font-size: 200%; }

hr {
  width: 200px;
  margin: 0 auto 50px auto;
  height: 2px;
  border: none;
  background-color: var(--red-color);
}

@media only screen and (max-width: 1200px) {
  .page-wrapper { width: 85%; }
}

@media only screen and (max-width: 896px) {
  .page-wrapper { width: 95%; }
}

@media only screen and (max-width: 767px) {
  hr { margin-bottom: 30px; }
}

@media only screen and (max-width: 480px) {
  .page-title { font-size: 275%; }
  .small-page-title.page-title { font-size: 150%; }
}