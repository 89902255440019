.teachers-large-screen { display: block; }
.teachers-small-screen { display: none; }

.teacher-chelsea {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  margin-bottom: 30px;
}

.teachers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
}

.teacher-text { 
  line-height: 145%; 
  align-self: center;
}

.teacher-name {
  font-weight: 600;
  font-size: 110%;
  letter-spacing: 2px;
  margin-bottom: 5px;
}

.teacher-position {
  font-family: var(--justlovely);
  font-size: 150%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 480px) {
  .teachers-large-screen { display: none; }
  .teachers-small-screen { 
    display: block;
    margin-left: 50px; 
  }
}
