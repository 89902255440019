.App {
  background-color: #fff;
  position: relative;
  min-height: 100vh;
}

.red-text { color: var(--red-color); }

.grow { 
  -webkit-transition: all .2s ease-in-out; 
  transition: all .2s ease-in-out; 
}

.grow:hover { 
  -webkit-transform: scale(1.1); 
  transform: scale(1.1); 
}

table {
  border-collapse: collapse;
  table-layout: fixed;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

a { color: var(--font-color); }
a:hover { color: var(--red-color); }

@media only screen and (max-width: 480px) {
  table td, table th { padding: 8px 4px; }
}